.products{
    padding: 0px;
}
  
/* Center the pagination */
.pagination {
    display: block;
    width: 100%;
    padding-left: 5px;
    padding-top: 15px;
  }
  
  .pagination .pages {
    display: flex;
    width: fit-content;
    margin: auto;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: lowercase;
  }

  
  .pagination .pagesNumber {
    display: inline-flex;
    border: 1px solid #0979be;
    height:30px
  }
  .pagination .pages .prev{
    border: 1px solid #0979be;

    background-color: #ffffff;
    color:#0979be;
    border-right: none;
    padding: 3px 5px 5px 5px;
    border-radius: 10px 0px 0px 10px;
    width:70px;
    height: 30px;
    text-align: center;
    font-size: 14px;
  }
  .pagination .pages .prev:disabled{
    border: 1px solid #999999;
    background-color: #f8f9fa;
    color:#999999;
  }
  .pagination .pages .prev:hover:enabled{
    border: 1px solid #0979be;
    background-color: #0979be;
    color:#f8f9fa;
    cursor: pointer;
  }
  .pagination .pages .next{
    border: 1px solid #0979be;
    background-color: #ffffff;
    color:#0979be;
    border-left: none;
    padding: 3px 5px 5px 5px;
    border-radius: 0px 10px 10px 0px;
    width:70px;
    height: 30px;
    text-align: center;
    font-size: 14px;
  }
  .pagination .pages .next:disabled{
    border: 1px solid #999999;
    background-color: #f8f9fa;
    color:#999999;
  }
  .pagination .pages .next:hover:enabled{
    border: 1px solid #0979be;
    background-color: #0979be;
    color:#f8f9fa;
    cursor: pointer;
  }
  
  .pagination .pagesNumber .number{
    font-weight: bold;
    cursor: pointer;
    width: 30px;
    padding-top: 2px;
    text-align: center;
  }
  .pagination .pagesNumber .active{
    background-color: #0979be;
    color: #f8f9fa;
    text-decoration:underline;
  }
  
  .pagination .pagesNumber .number:hover{
    background-color: #0979be;
    color: #f8f9fa;
}


  .searchResume{
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    color:#0979be;
    display:block;
    width: 100%;
    padding-left: 5px;
    padding-bottom: 5px;
    padding-top: 0px;
    border-bottom: 2px solid #0979be;
    height:30px;
  }

  .searchResume .total{
    float:right;
  }
  
  .searchResume .title{
   float: left;
  }
  
  .filterMenu {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .filterMenu .sortBy {
    display: flex;
    align-items: center;
  }
  
  .filterMenu .sortBy > div {
    display: inline-block;
    margin-right: 10px;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
  }
  
  .filterMenu select {
    padding: 0.375rem 0.75rem;
    margin: 0 0.25rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #f8f9fa;
  }
  
  .filterMenu .sortBy select {
    margin-right: 10px;
  }
  
.btn-filter{
  font-size: 13px;
  color:#999999;
  background-image: url("../imgs/interface/filter.png");
  background-repeat: no-repeat;
  margin-top: 5px;
  background-size: 18px 20px;
  background-position: center 5px;
  width: 50px;
  height: 50px;
  display: block;
  cursor: pointer;
  padding-top: 28px;
  text-align: center;
  margin-left: auto;
}
.btn-filter:hover{
  color:#0979be;
  background-image: url("../imgs/interface/filter-over.png");

}


.menuFilterTop{
  display: flex;
}


.products {
  display: flex;
}
.menuTop{
  width: 100%;
  height:40px;
  display: inline-flex;
  border-bottom:  solid thin #dddddd;
}
.menuTop .logo{
  height: 22px;
  margin-top: 9px;
  padding-left: 10px;
}

.appRight{
flex: 1;  /* grow */
display: block;
height: 100vh;
overflow-x: auto;
padding-bottom: 30px;
}
.appRight h3{
font-size: 22px;
margin: 10px 0px 0px 0px;
padding-left: 20px;
text-align: center;
letter-spacing: 3px;
font-weight: 600;
}
.fullRow{
width: 100%;
}
.noDecoration{
text-decoration:none;
}
.cardProductInfo{
margin-top: 20px;
border: none;
}

.cardProductInfo h3{
padding-top: 10px;
padding-left: 10px;
padding-bottom: 20px;
letter-spacing: 2px;
font-size: 32px;
}

.detailTitle{
text-align: left;
font-size: 16px;
letter-spacing: 2px;
font-weight: bold;
color:#666666;
height: 30px;
line-height: 30px;
width: 100%;
min-width: fit-content;
padding-left:15px;
}
.detailsRow{
min-height: 25px;
line-height: 25px;
font-size: 16px;
font-weight: normal;
margin-bottom:5px;
width: 100%;

padding-left:15px;
}

.year{
  width: 10%;
  display: block;
}
.descriptionText{
  padding: 10px 10px 10px 10px;
  border:none;
  }

  .dimession{
    padding-left: 0px;
  }


  .stock{
    display: block;
    float: right;
    margin-top: 30px;
    margin-right: 15px;;
  }
  
  
.wrapp{
margin-left: 50%;
margin-top: 80px;
transform: translateX(-40px);
}

.favoryBtn{
display: block;
width: 30px;
height: 40px;
line-height: 40px;
float: right;
cursor: pointer;
margin-right: 10px;
}
.favoryBtn img{
width: 30px;
height: 30px;
margin: auto;
}

.btnAddCart {
display: block;
float: right;
width: 40px;
height: 40px;
background-image: url('../imgs/interface/bigCart.png');
background-size: 30px 30px;
background-repeat: no-repeat;
background-position: center center;
cursor: pointer;
}  
.addToCart{
background-color: red;
width: 20px;
height: 20px;
float: right;
border-radius: 15px;
color:#fff;
text-align: center;
line-height: 20px;
font-size: 20px;
}

.productTitle{
padding: 10px 15px;
color:#fff;
margin-top: 10px;
background-color:#0979be  ;
max-height:40px;
}
.disponible{
float: left;
height: 40px;
font-size: 20px;
font-weight: bold;
line-height: 40px;
}
.disponibleCard{
float: left;
height: 40px;
line-height: 40px;
padding-left: 15px;
}
.dispoTitle {
margin-top: 40px;
margin-bottom: 20px;

letter-spacing: 4px;

}

.dispo{
margin-top: 40px;
border-top: 2px solid #a7e4e2;
}



.btnDetails{
margin-top: 15px;
margin-bottom: 0px;
}

.montantLocation{
display: block;
text-align: center;
height:150px;
font-weight: 900;
font-size: 22px;
line-height: 150px;
text-transform: uppercase;
background-color: #0979be;
color: #fff;
border: thin solid #fff;
border-bottom: none;
border-radius: 10px 10px 0px 0px;
}
.tarif{
padding-top: 10px;
padding-bottom: 10px;
text-align: center;
background-color: #0bb997;
text-transform: uppercase;
color: #fff;
border: thin solid #fff;
}
.tarifTitre{
border: thin solid #fff;
background-color: #a7e4e2;
color: #0979be;
padding-top: 10px;
padding-bottom: 10px;
text-align: center;
border-top: none;
}

.valeur{
border: thin solid #fff;
background-color: #0bb997;
text-align: center;
border-radius: 10px 10px 0px 0px;
font-size: 22px;
font-weight: 900;
letter-spacing: 2px;
padding-top: 50px;
}

.ficheDetail{
padding-bottom: 30px;
}
.ficheDetail .row{
padding-top: 5px;
padding-bottom: 5px;

}


.card-item{
margin-top: 20px;
cursor: pointer;
overflow: hidden;
border: thin solid #0979be;
  margin-top: 20px;
color:#0979be;
}


.card-item:hover{
box-shadow:2px 2px 10px 10px #d2d2d2;
}

.card-item-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 0; /* Set initial height to 0 */
  padding-top: 100%; /* Create a square by setting padding-top to 100% */
  position: relative; /* Required for absolute positioning */
}

.card-item-img img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Change this from 'cover' to 'contain' */
  position: absolute; /* Position the image within the square container */
  top: 0;
  left: 0;
}




.selectBox select{
  padding: 0.375rem 0.75rem;
  margin: 0;
  border: none;
  border-radius: 0.25rem;
  background-color: #f8f9fa;
  color:#434343;

}

@media (max-width: 765px) {



  .searchResume{
    height:auto;
  }

  .searchResume .total{
    float:none;
  }
  
  .searchResume .title{
   float: none;
  }
  

}