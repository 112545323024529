@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");


body {
  margin: 0px;
  padding: 0px;
  font-family: "Oswald", sans-serif;
  background-color: #ffffff;
  color: #0979be;
}

a {
  color: #0979be;
  text-decoration: none;
}


.btn-primary {
  background-color: #0979be;
  border: 0px;
  color: #ffffff;
}
.btn-primary:focus{
  background-color: #0979be;
}
.btn-primary:active{
  background-color: #0979be;
}


.btn-primary:hover {
  background-color: #0979be;
  color: #a7e4e2;
}


.mini-card {
  color: #4d5d73;
  margin-top: 0px;
  padding: 5px;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    
}

.card-date{
  color: #4d5d73;
  margin-top: 0px;
  padding: 5px;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.card-date .info {
  padding-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}


.card-date .img {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  max-width: 80px;
  max-height: 80px;
}

.card-date .img img {
  max-width: 100%;
  max-height: 100%;
}


.card-date .info .card-subtitle{
  font-size: 14px;
  text-transform: lowercase;
  font-weight: bold;
  display: flex;  
  height: 100%;
  align-items: center;
  
}



.mini-card .img {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  max-width: 80px;
  max-height: 80px;
}

.mini-card .img img {
  max-width: 100%;
  max-height: 100%;
}

.mini-card .info{
  padding-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.mini-card .info .card-title{
  font-size: 14px;
  text-transform: capitalize;
  font-weight: bold;
  border-radius: 5px;
  text-align: left;
  background: none;
  color:#232f3e;
  padding: 0px;
  margin: 0px;
  margin-left: 5px;
}



.mini-card .info .card-subtitle{
  font-size: 12px;
  text-transform: lowercase;
  display: flex;  
  align-items: center;
}

.mini-card .info .card-subtitle .small-icon{
  font-size: 14px;
  margin-right: 3px;
}


.info-title{
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  
  text-align: left;
  padding: 10px;

}


/* Set the width and height of the scrollbar */
::-webkit-scrollbar {
  width: 5px; /* Adjust this value to make it thinner */
  height: 5px; /* Adjust this value to make it shorter */
}

/* Set the background color of the scrollbar track */
::-webkit-scrollbar-track {
  background: linear-gradient(to bottom, #f1f1f1, #e6e6e6);
}

/* Set the color and border radius of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border-radius: 2px;
}

/* Add a hover effect to the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1a1;
}
