

.header-commande{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fa;
}


.header-commande .add-icon {
    font-size: 30px;
    cursor: pointer;
    margin-left: 10px;
    color: #0979be;
    padding:3px;
    border-radius: 3px;
  }
  
  .header-commande .add-icon:hover {
    color: #f3f3f3;
    background-color: #0979be;
  }
  


.header-btn{
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 10px;
}

.header-btn:hover{
    background-color:#0979be ;
    color:#f3f3f3;
}


.article-title{
    border-bottom: solid 1px #f8f9fa;
    display: flex;
    justify-content: space-between;
    padding: 5px;
}


.order-row {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding:10px;
    border-radius: 5px;
  }
  .order-row:hover{
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  
  
  .order-row .img {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #f3f3f3;
    margin-left: 10px;
  }
  
  .order-row .img img{
    max-width:50px;
    max-height:50px;
    border-radius: 5px;
  }
  
  .order-row .number{
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 5px;
  }
  
  .order-row .product-info{
    display: block;
    margin-left: 10px;
    width: 100%;
  }
  
  .order-row .btn-right{
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  
  
  .order-row input{
    width:40px;
    font-size: 12px;
    border-radius: 5px;
  }
  
  .order-row .product-name{
    overflow: hidden;
    white-space: nowrap;
  }
  