/* pdf.css */
.pdfPage {
  margin: 10;
  flex-grow: 1;
  border-radius: 20;
  border: 3px solid #232f3e;
  }
  
  .pdfPage h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .pdfPage h2 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .pdfPage p {
    font-size: 16px;
    margin-bottom: 5px;
  }
  